import { IAgency } from '../types'

import { IAgencyPayload } from './types'

export const fromDataToPayload = (data: IAgency): IAgencyPayload => ({
  streamers_participate: data.streamersParticipate,
  use_dark_market: data.useDarkMarket,
  commission: data.commission || null,
  external_cpm: {
    video: +data.externalCpm.video || null,
    pip_video: +data.externalCpm.pip || null,
    custom: +data.externalCpm.custom || null,
    interactive: +data.externalCpm.interactive || null,
    preroll: +data.externalCpm.preroll || null,
    leaderboard: null,
    yandex_fs: null,
  },
  internal_cpm: {
    video: +data.internalCpm.video || null,
    pip_video: +data.internalCpm.pip || null,
    custom: +data.internalCpm.custom || null,
    interactive: +data.internalCpm.interactive || null,
    preroll: +data.internalCpm.preroll || null,
    leaderboard: null,
    yandex_fs: null,
  },
  external_cpa: +data.externalCpa || null,
  internal_cpa: +data.internalCpa || null,
  external_cpc: +data.externalCpc || null,
  internal_cpc: +data.internalCpc || null,
  dark_market_external_cpm: {
    video: +data.darkMarketExternalCpm.video || null,
    pip_video: +data.darkMarketExternalCpm.pip || null,
    custom: +data.darkMarketExternalCpm.custom || null,
    interactive: +data.darkMarketExternalCpm.interactive || null,
    preroll: +data.darkMarketExternalCpm.preroll || null,
    leaderboard: null,
    yandex_fs: null,
  },
  dark_market_internal_cpm: {
    video: +data.darkMarketInternalCpm.video || null,
    pip_video: +data.darkMarketInternalCpm.pip || null,
    custom: +data.darkMarketInternalCpm.custom || null,
    interactive: +data.darkMarketInternalCpm.interactive || null,
    preroll: +data.darkMarketInternalCpm.preroll || null,
    leaderboard: null,
    yandex_fs: null,
  },
  dark_market_external_cpa: +data.darkMarketExternalCpa || null,
  dark_market_internal_cpa: +data.darkMarketInternalCpa || null,
  dark_market_external_cpc: +data.darkMarketExternalCpc || null,
  dark_market_internal_cpc: +data.darkMarketInternalCpc || null,
  ignore_categories: data.ignoredCategories,
})
